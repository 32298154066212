import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding} from '@fortawesome/free-solid-svg-icons/faBuilding';
import {faCalendarWeek} from '@fortawesome/free-solid-svg-icons/faCalendarWeek';
import {faCity} from '@fortawesome/free-solid-svg-icons/faCity';
import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import {faIndustry} from '@fortawesome/free-solid-svg-icons/faIndustry';
import {faMap} from '@fortawesome/free-solid-svg-icons/faMap';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons/faDollarSign';
import {faMoneyBillWave} from '@fortawesome/free-solid-svg-icons/faMoneyBillWave';
import {faTags} from '@fortawesome/free-solid-svg-icons/faTags';
import React from 'react';
import {NavLink} from 'react-router-dom';

class Slot extends React.Component {
  render = () => {
    return (
      <React.Fragment>
        {this.renderOne()}
        {this.renderTwo()}
      </React.Fragment>
    );
  };

  renderOne = () => {
    return (
      <ul className="mb-3 nav nav-tabs">
        <li className="nav-item">{this.renderOneSetUp()}</li>
        <li className="nav-item">{this.renderOneWeeklyUpdates()}</li>
      </ul>
    );
  };

  renderOneSetUp = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildOne('Set-up')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faClipboardCheck} />
          Set-up
        </a>
      );
    }
    return (
      <NavLink className={this.buildOne('Set-up')} to={`${this.props.url(this.props.match.params)}/set-up/community`}>
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faClipboardCheck} />
        Set-up
      </NavLink>
    );
  };

  renderOneWeeklyUpdates = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildOne('Weekly Updates')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
          Weekly Updates
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildOne('Weekly Updates')}
        to={`${this.props.url(this.props.match.params)}/weekly-updates/unit-mix-details`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
        Weekly Updates
      </NavLink>
    );
  };

  renderTwo = () => {
    return (
      <React.Fragment>
        {this.renderTwoSetUp()}
        {this.renderTwoWeeklyUpdates()}
      </React.Fragment>
    );
  };

  renderTwoSetUp = () => {
    if (this.props.one !== 'Set-up') {
      return null;
    }
    return (
      <ul className="flex-column flex-lg-row nav nav-pills nav-justified">
        <li className="mb-3 ml-lg-0 nav-item">{this.renderTwoSetUpCommunity()}</li>
        <li className="mb-3 ml-lg-3 nav-item">{this.renderTwoSetUpUtilitiesAndFeatures()}</li>
        <li className="mb-3 ml-lg-3 nav-item">{this.renderTwoSetUpFees()}</li>
        <li className="mb-3 ml-lg-3 nav-item">{this.renderTwoSetUpUnitMixDetails()}</li>
        <li className="mb-3 ml-lg-3 nav-item">{this.renderTwoSetUpAutomatedPricing()}</li>
        <li className="mb-3 ml-lg-3 nav-item">{this.renderTwoSetUpCompetitors()}</li>
        {this.props.classification === 'Cardinal Community' ? (
          <li className="mb-3 ml-lg-3 nav-item">{this.renderTwoSetUpWeeklyGoals()}</li>
        ) : null}
      </ul>
    );
  };

  renderTwoWeeklyUpdates = () => {
    if (this.props.one !== 'Weekly Updates') {
      return null;
    }
    return (
      <ul className="flex-column flex-lg-row nav nav-pills nav-justified">
        <li className="mb-3 ml-lg-0 nav-item">{this.renderTwoWeeklyUpdatesUnitMixDetails()}</li>
        <li className="mb-3 ml-lg-3 nav-item">{this.renderTwoWeeklyUpdatesPreleaseAndOccupancy()}</li>
      </ul>
    );
  };

  renderTwoSetUpCommunity = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Community')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faBuilding} />
          Community
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Community')}
        to={`${this.props.url(this.props.match.params)}/set-up/community`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faBuilding} />
        Community
      </NavLink>
    );
  };

  renderTwoSetUpUtilitiesAndFeatures = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Utilities & Features')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faIndustry} />
          Utilities & Features
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Utilities & Features')}
        to={`${this.props.url(this.props.match.params)}/set-up/utilities-and-features`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faIndustry} />
        Utilities & Features
      </NavLink>
    );
  };

  renderTwoSetUpFees = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Fees')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faMoneyBillWave} />
          Fees
        </a>
      );
    }
    return (
      <NavLink className={this.buildTwo('Fees')} to={`${this.props.url(this.props.match.params)}/set-up/fees`}>
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faMoneyBillWave} />
        Fees
      </NavLink>
    );
  };

  renderTwoSetUpUnitMixDetails = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Unit Mix Details')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faMap} />
          Unit Mix Details
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Unit Mix Details')}
        to={`${this.props.url(this.props.match.params)}/set-up/unit-mix-details`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faMap} />
        Unit Mix Details
      </NavLink>
    );
  };

  renderTwoSetUpAutomatedPricing = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Automated Website Retrieval')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faDollarSign} />
          Automated Website Retrieval
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Automated Website Retrieval')}
        to={`${this.props.url(this.props.match.params)}/set-up/automated-pricing`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faDollarSign} />
        Automated Website Retrieval
      </NavLink>
    );
  };

  renderTwoSetUpCompetitors = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Competitors')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faCity} />
          Competitors
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Competitors')}
        to={`${this.props.url(this.props.match.params)}/set-up/competitors`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faCity} />
        Competitors
      </NavLink>
    );
  };

  renderTwoSetUpWeeklyGoals = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Weekly Goals')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
          Weekly Goals
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Weekly Goals')}
        to={`${this.props.url(this.props.match.params)}/set-up/weekly-goals`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
        Weekly Goals
      </NavLink>
    );
  };

  renderTwoWeeklyUpdatesUnitMixDetails = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Unit Mix Details')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faMap} />
          Unit Mix Details
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Unit Mix Details')}
        to={`${this.props.url(this.props.match.params)}/weekly-updates/unit-mix-details`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faMap} />
        Unit Mix Details
      </NavLink>
    );
  };

  renderTwoWeeklyUpdatesPreleaseAndOccupancy = () => {
    if (this.buildCommunityID() === null) {
      return (
        <a className={this.buildTwo('Prelease & Occupancy')}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faTags} />
          Prelease & Occupancy
        </a>
      );
    }
    return (
      <NavLink
        className={this.buildTwo('Prelease & Occupancy')}
        to={`${this.props.url(this.props.match.params)}/weekly-updates/prelease-and-occupancy`}
      >
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faTags} />
        Prelease & Occupancy
      </NavLink>
    );
  };

  buildOne = (one) => {
    if (this.buildCommunityID() === null) {
      return one === this.props.one ? 'active disabled nav-link' : 'disabled nav-link';
    }
    return one === this.props.one ? 'active nav-link' : 'nav-link';
  };

  buildTwo = (two) => {
    if (this.buildCommunityID() === null) {
      return two === this.props.two ? 'active disabled nav-link' : 'bg-dark disabled nav-link text-white';
    }
    return two === this.props.two ? 'active nav-link' : 'bg-dark nav-link text-white';
  };

  buildCommunityID = () => {
    if (this.props.match.params.id === undefined) {
      return null;
    }
    return this.props.match.params.id;
  };
}

export {Slot};
