import {Form as F, Formik} from 'formik';
import React from 'react';
import * as yup from 'yup';

import {buildCancel, buildSave, buildSaveAndNext, buildTimestamp} from '../builders';
import {CLASSES, MANAGEMENTS, PRICING_TOOLS, STATES, STYLES, TYPES, PROXIMITIES} from '../constants';
import {Slot as Group} from '../slots/group';
import {Slot as Prompt} from '../slots/prompt';

class Form extends React.Component {
  render = () => {
    return (
      <Formik
        initialValues={this.buildInitialValues()}
        onSubmit={this.props.handleSubmit}
        render={this.buildRender}
        validationSchema={this.buildValidationSchema()}
      />
    );
  };

  renderPropertyCode = (errors, touched) => {
    if (this.props.classification === 'Non-Cardinal Community') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input"
          disabled={this.buildDisabled('property_code')}
          errors={errors['property_code']}
          label="Property Code"
          name="property_code"
          touched={touched['property_code']}
          type="text"
        />
      </div>
    );
  };

  renderGroup = (errors, touched) => {
    if (this.props.classification === 'Non-Cardinal Community') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input"
          disabled={this.buildDisabled('group')}
          errors={errors['group']}
          label="Group"
          name="group"
          touched={touched['group']}
          type="text"
        />
      </div>
    );
  };

  renderCell = (errors, touched) => {
    if (this.props.classification === 'Non-Cardinal Community') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input"
          disabled={this.buildDisabled('cell')}
          errors={errors['cell']}
          label="Cell"
          name="cell"
          touched={touched['cell']}
          type="text"
        />
      </div>
    );
  };

  renderType = (errors, touched) => {
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="select-one"
          disabled={this.buildDisabled('type')}
          errors={errors['type']}
          label="Type"
          name="type"
          options={TYPES}
          touched={touched['type']}
        />
      </div>
    );
  };

  renderMarket = (errors, touched) => {
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input"
          disabled={this.buildDisabled('market')}
          errors={errors['market']}
          label="Market"
          name="market"
          touched={touched['market']}
          type="text"
        />
      </div>
    );
  };

  renderManagement = (setFieldValue, errors, touched, values) => {
    if (this.props.classification === 'Cardinal Community') {
      return (
        <Group
          component="input-management"
          errors={errors['management']}
          isDisabled={true}
          label="Management"
          name="management"
          onChange={(value) => {
            if (value.value !== undefined) {
              setFieldValue('management', value.value);
            }
          }}
          options={MANAGEMENTS}
          touched={touched['management']}
          value={values.management}
        />
      );
    }
    return (
      <Group
        component="input-management"
        errors={errors['management']}
        isDisabled={this.buildDisabled('management')}
        label="Management"
        name="management"
        onChange={(value) => {
          if (value.value !== undefined) {
            setFieldValue('management', value.value);
          }
        }}
        options={MANAGEMENTS}
        touched={touched['management']}
        value={values.management}
      />
    );
  };

  renderScrapingEnabled = (setFieldValue, errors, touched, values) => {
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="checkbox"
          checked={values.pricing.scraping_enabled}
          disabled={this.buildDisabled('pricing.scraping_enabled')}
          errors={errors['pricing.scraping_enabled']}
          label="Enable Price Retrieval"
          name="pricing.scraping_enabled"
          onChange={(event) =>
            setFieldValue('pricing.scraping_enabled', event.target.checked)
          }
          special={true}
          touched={touched['pricing.scraping_enabled']}
        />
      </div>
    );
  };

  buildDisabled = (name) => {
    if (['HQ', 'CM'].indexOf(this.props.context.type) === -1) {
      return true;
    }
    if (this.props.classification === 'Cardinal Community') {
      if (name === 'property_code') {
        return true;
      }
      if (name === 'group') {
        return true;
      }
      if (name === 'cell') {
        return true;
      }
      if (name === 'market') {
        return true;
      }
      if (name === 'name') {
        return true;
      }
      if (name === 'city') {
        return true;
      }
      if (name === 'state') {
        return true;
      }
      if (name === 'units') {
        return true;
      }
      if (name === 'beds') {
        return true;
      }
      if (name === 'type') {
        return true;
      }
      if (name === 'style') {
        return true;
      }
      if (name === 'management') {
        return true;
      }
      if (name === 'pricing_tool') {
        return true;
      }
    }
    return false;
  };

  buildInitialValues = () => {
    if (this.props.community === null) {
      return {
        property_code: '',
        group: '',
        cell: '',
        market: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: 0,
        website: '',
        year: 0,
        units: 0,
        beds: 0,
        type: '',
        style: '',
        class: '',
        proximity: '',
        management: '',
        manager: '',
        pricing_tool: '',
        pricing: {
          scraping_enabled: false,
          specials_url: '',
          floor_plans_url: '',
        },
      };
    }
    return {
      property_code: this.props.community.property_code || '',
      group: this.props.community.group || '',
      cell: this.props.community.cell || '',
      market: this.props.community.market || '',
      name: this.props.community.name || '',
      address: this.props.community.address || '',
      city: this.props.community.city || '',
      state: this.props.community.state || '',
      zip: this.props.community.zip || '',
      phone: this.props.community.phone || 0,
      website: this.props.community.website || '',
      year: this.props.community.year || 0,
      units: this.props.community.units || 0,
      beds: this.props.community.beds || 0,
      type: this.props.community.type || '',
      style: this.props.community.style || '',
      class: this.props.community.class || '',
      proximity: this.props.community.proximity || '',
      management: this.props.community.management || '',
      manager: this.props.community.manager || '',
      pricing_tool: this.props.community.pricing_tool || '',
      pricing: this.props.community.pricing ?
        {
          scraping_enabled: this.props.community.pricing.scraping_enabled ,
          specials_url: this.props.community.pricing.specials_url,
          floor_plans_url: this.props.community.pricing.floor_plans_url,
        } :
        {
          scraping_enabled:  false,
          specials_url: '',
          floor_plans_url: '',
        },
    };
  };

  buildRender = ({
    dirty,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitCount,
    submitForm,
    touched,
    values,
  }) => {
    return (
      <F>
        <Prompt dirty={dirty} submitCount={submitCount} />
        <div className="row">
          {this.renderPropertyCode(errors, touched)}
          {this.renderGroup(errors, touched)}
          {this.renderCell(errors, touched)}
          {this.renderType(errors, touched)}
          {this.renderMarket(errors, touched)}
        </div>
        <div className="row">
          <div className="col-sm-12 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('name')}
              errors={errors['name']}
              label="Name"
              name="name"
              touched={touched['name']}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('address')}
              errors={errors['address']}
              label="Address"
              name="address"
              touched={touched['address']}
              type="text"
            />
          </div>
          <div className="col-sm-3 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('city')}
              errors={errors['city']}
              label="City"
              name="city"
              touched={touched['city']}
              type="text"
            />
          </div>
          <div className="col-sm-3 col-md">
            <Group
              component="select-one"
              disabled={this.buildDisabled('state')}
              errors={errors['state']}
              label="State"
              name="state"
              options={STATES}
              touched={touched['state']}
            />
          </div>
          <div className="col-sm-3 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('zip')}
              errors={errors['zip']}
              label="ZIP"
              name="zip"
              touched={touched['zip']}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md">
            <Group
              component="input-mask"
              disabled={this.buildDisabled('phone')}
              errors={errors['phone']}
              label="Phone"
              mask="999-999-9999"
              name="phone"
              onBlur={() => setFieldTouched('phone', true)}
              onChange={(event) => setFieldValue('phone', parseInt(event.target.value.replace(/-/g, ''), 10))}
              touched={touched['phone']}
              type="number"
              value={values.phone}
            />
          </div>
          <div className="col-sm-12 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('website')}
              errors={errors['website']}
              label="Website"
              name="website"
              touched={touched['website']}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('year')}
              errors={errors['year']}
              label="Year Built"
              max={new Date().getFullYear()}
              min="1800"
              name="year"
              touched={touched['year']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('units')}
              errors={errors['units']}
              label="Units"
              min="0"
              name="units"
              touched={touched['units']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('beds')}
              errors={errors['beds']}
              label="Beds"
              min="0"
              name="beds"
              touched={touched['beds']}
              type="number"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md">
            <Group
              component="select-one"
              disabled={this.buildDisabled('style')}
              errors={errors['style']}
              label="Style"
              name="style"
              options={STYLES}
              touched={touched['style']}
            />
          </div>
          <div className="col-sm-12 col-md">
            <Group
              component="select-one"
              disabled={this.buildDisabled('class')}
              errors={errors['class']}
              label="Class"
              name="class"
              options={CLASSES}
              touched={touched['class']}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md">
            <Group
              component="select-one"
              disabled={this.buildDisabled('proximity')}
              errors={errors['proximity']}
              label="Proximity"
              name="proximity"
              options={PROXIMITIES}
              touched={touched['proximity']}
            />
          </div>
          <div className="col-sm-12 col-md">{this.renderManagement(setFieldValue, errors, touched, values)}</div>
          <div className="col-sm-12 col-md">
            <Group
              component="input"
              disabled={this.buildDisabled('manager')}
              errors={errors['manager']}
              label="Manager"
              name="manager"
              touched={touched['manager']}
              type="text"
            />
          </div>
          <div className="col-sm-12 col-md">
            <Group
              component="select-one"
              disabled={this.buildDisabled('pricing_tool')}
              errors={errors['pricing_tool']}
              label="Pricing Tool"
              name="pricing_tool"
              options={PRICING_TOOLS}
              touched={touched['pricing_tool']}
            />
          </div>
        </div>
        <div className="row">
          {this.renderScrapingEnabled(setFieldValue, errors, touched, values)}
          <div className="col-sm-3 col-md">
            <Group
              component="url"
              disabled={this.buildDisabled('pricing.specials_url')}
              errors={errors.pricing && errors.pricing.specials_url}
              label="Specials URL (homepage)"
              name="pricing.specials_url"
              touched={touched.pricing && touched.pricing.specials_url}
              type="text"
            />
          </div>
          <div className="col-sm-3 col-md">
            <Group
              component="url"
              disabled={this.buildDisabled('pricing.floor_plans_url')}
              errors={errors.pricing && errors.pricing.floor_plans_url}
              label="Floor Plans URL"
              name="pricing.floor_plans_url"
              touched={touched.pricing && touched.pricing.floor_plans_url}
              type="text"
            />
          </div>
        </div>
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <p className="align-items-center d-flex flex-fill justify-content-start mb-3">
            Last Update:
            <strong className="ml-2">{buildTimestamp(this.buildTimestamp())}</strong>
          </p>
          <div className="d-flex flex-fill justify-content-end mb-3">
            {buildCancel(() => this.props.history.goBack())}
            {buildSave(errors, isSubmitting, setFieldValue, submitForm)}
            {buildSaveAndNext(errors, isSubmitting, setFieldValue, submitForm)}
          </div>
        </div>
      </F>
    );
  };

  buildTimestamp = () => {
    if (this.props.community === null) {
      return null;
    }
    return this.props.community.community_updated_at;
  };

  buildValidationSchema = () => {
    if (this.props.classification === 'Cardinal Community') {
      return yup.object().shape({
        address: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
        city: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
        state: yup.string().required(),
        zip: yup
          .string()
          .matches(/^[0-9-]+$/)
          .required(),
        phone: yup
          .string()
          .length(10)
          .matches(/^[0-9-]+$/)
          .required(),
        website: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
        year: yup
          .number()
          .integer()
          .max(new Date().getFullYear())
          .min(1801)
          .required(),
        class: yup.string().required(),
        proximity: yup.string().when('type', {
          is: (value) => value === 'Conventional',
          then: yup.string().notRequired(),
          otherwise: yup.string().required(),
        }),
        manager: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
      });
    }
    if (this.props.classification === 'Non-Cardinal Community') {
      return yup.object().shape({
        market: yup.string().required(),
        name: yup.string().required(),
        address: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
        city: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
        state: yup.string().required(),
        zip: yup
          .string()
          .matches(/^[0-9-]+$/)
          .required(),
        phone: yup
          .string()
          .length(10)
          .matches(/^[0-9-]+$/)
          .required(),
        website: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
        year: yup
          .number()
          .integer()
          .max(new Date().getFullYear())
          .min(1801)
          .required(),
        units: yup
          .number()
          .integer()
          .positive()
          .required(),
        type: yup.string().required(),
        beds: yup.number().when('type', {
          is: (value) => value === 'Conventional',
          then: yup
            .number()
            .integer()
            .positive()
            .min(0)
            .notRequired(),
          otherwise: yup
            .number()
            .integer()
            .positive()
            .required(),
        }),
        style: yup.string().required(),
        class: yup.string().required(),
        proximity: yup.string().when('type', {
          is: (value) => value === 'Conventional',
          then: yup.string().notRequired(),
          otherwise: yup.string().required(),
        }),
        management: yup.string().required(),
        manager: yup
          .string()
          .required()
          .max(256, 'Enter up to 256 characters'),
      });
    }
    return yup.object().shape({});
  };
}

export {Form};
