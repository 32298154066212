import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {toast} from 'react-toastify';
import {setLocale} from 'yup';

import 'jquery';
import 'popper.js';
import 'bootstrap';

import 'typeface-open-sans';
import 'typeface-oswald';

axios.defaults.baseURL = '/api';

axios.defaults.timeout = 10000;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      history.push('/api/sign-in');
    }
    return Promise.reject(error);
  },
);

setLocale({
  mixed: {
    default: 'Invalid Value',
    notType: 'Invalid Value',
    required: 'Invalid Value',
  },
  number: {
    integer: 'Invalid Value',
    max: 'Invalid Value',
    min: 'Invalid Value',
    positive: 'Invalid Value',
    required: 'Invalid Value',
  },
  string: {
    length: 'Invalid Value',
  },
});

toast.configure({
  autoClose: false,
  closeOnClick: false,
  draggable: false,
  hideProgressBar: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
});

import {Route as CommunitiesInsert} from './routes/communities-insert';
import {Route as CommunitiesSelect} from './routes/communities-select';
import {Route as CommunitiesUpdateCommunity} from './routes/communities-update-community';
import {Route as CommunitiesUpdateCompetitors} from './routes/communities-update-competitors';
import {Route as CommunitiesUpdateFees} from './routes/communities-update-fees';
import {Route as CommunitiesUpdatePreleaseAndOccupancy} from './routes/communities-update-prelease-and-occupancy';
import {Route as CommunitiesUpdateUnitMixDetails} from './routes/communities-update-unit-mix-details';
import {Route as CommunitiesAutomatedPricing} from './routes/communities-automated-pricing';
import {Route as CommunitiesUpdateUtilitiesAndFeatures} from './routes/communities-update-utilities-and-features';
import {Route as CommunitiesUpdateWeeklyGoals} from './routes/communities-update-weekly-goals';
import {Route as CompetitorsSelect} from './routes/competitors-select';
import {Route as FAQ} from './routes/faq';
import {Route as SignIn} from './routes/sign-in';
import {Provider} from './securities';
import {history} from './singletons';

import './index.scss';

class Application extends React.Component {
  render = () => {
    return (
      <Provider>
        <Router history={history}>
          <Switch>
            <Route exact={true} path="/">
              <Redirect to="/cardinal-communities/select" />
            </Route>
            <Route
              component={(props) => <CommunitiesSelect classification="Cardinal Community" {...props} />}
              exact={true}
              path="/cardinal-communities/select"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateCommunity
                  classification="Cardinal Community"
                  next="/set-up/utilities-and-features"
                  one="Set-up"
                  two="Community"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/set-up/community"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateUtilitiesAndFeatures
                  classification="Cardinal Community"
                  next="/set-up/fees"
                  one="Set-up"
                  two="Utilities & Features"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/set-up/utilities-and-features"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateFees
                  classification="Cardinal Community"
                  next="/set-up/unit-mix-details"
                  one="Set-up"
                  two="Fees"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/set-up/fees"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateUnitMixDetails
                  classification="Cardinal Community"
                  next="/set-up/automated-pricing"
                  one="Set-up"
                  two="Unit Mix Details"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/set-up/unit-mix-details"
            />
            <Route
              component={(props) => (
                <CommunitiesAutomatedPricing
                  classification="Cardinal Community"
                  next="/set-up/competitors"
                  one="Set-up"
                  two="Automated Website Retrieval"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/set-up/automated-pricing"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateCompetitors
                  classification="Cardinal Community"
                  next="/set-up/weekly-goals"
                  one="Set-up"
                  two="Competitors"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/set-up/competitors"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateWeeklyGoals
                  classification="Cardinal Community"
                  next="/weekly-updates/unit-mix-details"
                  one="Set-up"
                  two="Weekly Goals"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/set-up/weekly-goals"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateUnitMixDetails
                  classification="Cardinal Community"
                  next="/weekly-updates/prelease-and-occupancy"
                  one="Weekly Updates"
                  two="Unit Mix Details"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/weekly-updates/unit-mix-details"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdatePreleaseAndOccupancy
                  classification="Cardinal Community"
                  next="/set-up/community"
                  one="Weekly Updates"
                  two="Prelease & Occupancy"
                  url={(community) => `/cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/cardinal-communities/:id/weekly-updates/prelease-and-occupancy"
            />
            <Route
              component={(props) => <CommunitiesSelect classification="Non-Cardinal Community" {...props} />}
              exact={true}
              path="/non-cardinal-communities/select"
            />
            <Route
              component={(props) => (
                <CommunitiesInsert
                  classification="Non-Cardinal Community"
                  next="/set-up/utilities-and-features"
                  one="Set-up"
                  two="Community"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/insert/set-up/community"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateCommunity
                  classification="Non-Cardinal Community"
                  next="/set-up/utilities-and-features"
                  one="Set-up"
                  two="Community"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/set-up/community"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateUtilitiesAndFeatures
                  classification="Non-Cardinal Community"
                  next="/set-up/fees"
                  one="Set-up"
                  two="Utilities & Features"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/set-up/utilities-and-features"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateFees
                  classification="Non-Cardinal Community"
                  next="/set-up/unit-mix-details"
                  one="Set-up"
                  two="Fees"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/set-up/fees"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateUnitMixDetails
                  classification="Non-Cardinal Community"
                  next="/set-up/competitors"
                  one="Set-up"
                  two="Unit Mix Details"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/set-up/unit-mix-details"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateCompetitors
                  classification="Non-Cardinal Community"
                  next="/set-up/weekly-goals"
                  one="Set-up"
                  two="Competitors"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/set-up/competitors"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateWeeklyGoals
                  classification="Non-Cardinal Community"
                  next="/weekly-updates/unit-mix-details"
                  one="Set-up"
                  two="Weekly Goals"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/set-up/weekly-goals"
            />
            <Route
              component={(props) => (
                <CommunitiesAutomatedPricing
                  classification="Non-Cardinal Community"
                  next="/set-up/competitors"
                  one="Set-up"
                  two="Automated Website Retrieval"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/set-up/automated-pricing"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdateUnitMixDetails
                  classification="Non-Cardinal Community"
                  next="/weekly-updates/prelease-and-occupancy"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  one="Weekly Updates"
                  two="Unit Mix Details"
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/weekly-updates/unit-mix-details"
            />
            <Route
              component={(props) => (
                <CommunitiesUpdatePreleaseAndOccupancy
                  classification="Non-Cardinal Community"
                  next="/set-up/community"
                  one="Weekly Updates"
                  two="Prelease & Occupancy"
                  url={(community) => `/non-cardinal-communities/${community.id}`}
                  {...props}
                />
              )}
              exact={true}
              path="/non-cardinal-communities/:id/weekly-updates/prelease-and-occupancy"
            />
            <Route component={(props) => <CompetitorsSelect {...props} />} exact={true} path="/competitors/select" />
            <Route component={FAQ} exact={true} path="/faq" />
            <Route component={SignIn} exact={true} path="/sign-in" />
            <Route
              path="/sign-out"
              component={() => {
                window.location.href = 'https://cgc.onelogin.com/portal/';
                return null;
              }}
            />
          </Switch>
        </Router>
      </Provider>
    );
  };
}

ReactDOM.render(<Application />, document.getElementById('application'));
