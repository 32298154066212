import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import {toast} from 'react-toastify';
import {withRouter} from 'react-router-dom';

import {buildCancel, buildNext, buildTitle} from '../builders';
import {withContext} from '../decorators';
import {Layout as Large} from '../layouts/large';
import {communitiesIDAutomatedPricingGet, communitiesIDAutomatedPricingSpecialsPost} from '../requests';
import {Slot as Tabs} from '../slots/tabs';

const AutomatedPricingTable = ({ automatedPricing }) => {
  const [status, setStatus] = useState([]);

  automatedPricing = automatedPricing
    .sort((a, b) => new Date(b.floor_plan_pricing_inserted_at) - new Date(a.floor_plan_pricing_inserted_at))
    .reduce((accumulator, item) => {
      const date = item.floor_plan_pricing_inserted_at ? item.floor_plan_pricing_inserted_at.split("T")[0] : "-";
      if (!accumulator[date]) {
        accumulator[date] = [];
      }
      accumulator[date].push(item);
      return accumulator;
    }, {});

  const dates = Object.keys(automatedPricing);

  useEffect(() => {
    setStatus(dates.slice(0, 3));
  }, []);

  return (
    <div>
      <h2 className="mb-3">Automated Website Retrieval</h2>
      {dates.length === 0 ? (
        <p>We do not have any automated pricing data for this community.</p>
      ) : (
        <div className="accordion" id="accordion">
          {dates.map((value, key) => (
            <div
              className={`card ${key === dates.length - 1 ? "mb-4" : ""}`}
              key={value}
            >
              <div
                className="align-items-center card-header d-flex justify-content-between"
                id={`heading-${key}`}
                onClick={() => status.includes(value) ? setStatus([...status.filter((v) => v !== value)]) : setStatus([...status, value])}
                style={{cursor: "pointer"}}
              >
                <div className="align-items-center d-flex justify-content-between mb-0 w-100">
                  {value}
                  {status.includes(value) ? <FontAwesomeIcon fixedWidth icon={faChevronUp}/> : <FontAwesomeIcon fixedWidth icon={faChevronDown}/>}
                </div>
              </div>
              <div className={`collapse ${status.includes(value) ? "show" : ""}`} id={`collapse-${key}`}>
                <div className="card-body">
                  <table className="mb-0 table table-striped table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th>Floor Plan</th>
                        <th>Description</th>
                        <th className="text-right">Beds</th>
                        <th className="text-right">Baths</th>
                        <th className="text-right">Sq. Ft.</th>
                        <th className="text-right">Min Price</th>
                        <th className="text-right">Max Price</th>
                        <th>Price Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      {automatedPricing[value].map((item, index) => (
                        <tr key={index}>
                          <td>{item.floor_plan_name}</td>
                          <td>{item.floor_plan_description || "-"}</td>
                          <td className="text-right">{item.beds || "-"}</td>
                          <td className="text-right">{item.baths || "-"}</td>
                          <td className="text-right">{item.square_footage || "-"}</td>
                          <td className="text-right">{item.market_rent_min ? `$${item.market_rent_min}` : "-"}</td>
                          <td className="text-right">{item.market_rent_max ? `$${item.market_rent_max}` : "-"}</td>
                          <td>{item.market_rent_label}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

class Route extends React.Component {
  state = {
    loading: false,
    exception: null,
    community: null,
    automatedPricing: [],
    specialsMode: "view",
    specialsText: "",
  };

  componentDidMount = () => {
    this.request();
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    communitiesIDAutomatedPricingGet(this.props.context.token, this.props.match.params.id).then(
      (response) => {
        this.setState({
          loading: false,
          exception: null,
          community: response.data.community,
          automatedPricing: response.data.automated_pricing,
          specialsMode: "view",
          specialsText: response.data.specials_text || "",
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          community: null,
          automatedPricing: [],
          specialsMode: "view",
          specialsText: "",
        });
      },
    );
  };

  handleSpecialsSave = (event) => {
    event.preventDefault();

    communitiesIDAutomatedPricingSpecialsPost(this.props.context.token, this.props.match.params.id, this.state.specialsText)
      .then(() => {
        toast.success(`Your specials was saved successfully.`);
        this.setState({ isEditingSpecials: false }, () => {
          this.request();
        });
      })
      .catch((_) => {
        toast.error(`Your specials was not saved successfully.`);
      });
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">
          {buildTitle(this.props.classification, this.state.community ? [this.state.community.name, 'Edit'] : [])}
        </h1>
        {this.renderTabs()}
        {this.renderLoading()}
        {this.renderAutomatedPricingTable()}
        {this.renderSpecialsSection()}
        <hr />
        <div className="d-flex flex-fill justify-content-end mb-3 mt-3">
          {buildCancel(() => this.props.history.goBack())}
          {buildNext(() =>
            this.props.history.push(
              `${this.props.url(this.state.community)}/set-up/competitors`
            )
          )}
        </div>
      </React.Fragment>
    );
  };

  renderTabs = () => {
    return (
      <Tabs
        classification={this.props.classification}
        match={this.props.match}
        one={this.props.one}
        two={this.props.two}
        url={this.props.url}
      />
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderAutomatedPricingTable = () => {
    if (this.state.automatedPricing.length === 0) {
      return null;
    }
    return <AutomatedPricingTable automatedPricing={this.state.automatedPricing} />;
  };

  renderSpecialsSection = () => {
    if (this.state.specialsMode === "edit") {
      return (
        <div className="mb-3">
          <h2 className="mb-3">Specials</h2>
          <textarea
            className="form-control mb-3"
            onChange={(event) => this.setState({ specialsText: event.target.value })}
            rows="4"
            value={this.state.specialsText}
          />
          <button className="btn btn-sm btn-success mr-2" onClick={this.handleSpecialsSave}>
            Save
          </button>
          <button className="btn btn-sm btn-danger" onClick={() => this.setState({ specialsMode: "view" })}>
            Cancel
          </button>
        </div>
      );
    }
    return (
      <div className="mb-3">
        <h2 className="mb-3">Specials</h2>
        <p className="mb-3">{this.state.specialsText || "We do not have any specials data for this community."}</p>
        <button className="btn btn-sm btn-success" onClick={(_) => this.setState({ specialsMode: "edit" })}>
          Edit
        </button>
      </div>
    );
  };

}

Route = withContext(Route, 'Private', Large);
Route = withRouter(Route);

export {Route};
