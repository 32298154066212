import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding} from '@fortawesome/free-solid-svg-icons/faBuilding';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {faHotel} from '@fortawesome/free-solid-svg-icons/faHotel';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import {format, parseISO} from 'date-fns';
import _ from 'lodash';
import React from 'react';

const buildCancel = (onClick) => {
  return (
    <a className="btn btn-danger ml-3 text-white" onClick={onClick}>
      <FontAwesomeIcon className="mr-2" fixedWidth icon={faTimesCircle} />
      Cancel
    </a>
  );
};

const buildClassName = (errors, touched) => {
  return touched && errors ? 'is-invalid' : '';
};

const buildLabel = (isSubmitting, label) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon
        className="mr-2"
        fixedWidth
        icon={isSubmitting ? faSpinner : faCheckCircle}
        spin={isSubmitting}
      />
      {label}
    </React.Fragment>
  );
};

const buildNext = (onClick) => {
  return (
    <button
      className="btn btn-success ml-3"
      data-toggle="tooltip"
      onClick={onClick}
      type="button"
    >
      Next
    </button>
  );
};

const buildSave = (errors, isSubmitting, setFieldValue, submitForm) => {
  return (
    <button
      className="btn btn-success ml-3"
      data-toggle="tooltip"
      disabled={Object.keys(errors).length > 0 ? true : isSubmitting}
      onClick={() => {
        setFieldValue('redirect', false, false);
        submitForm();
      }}
      title={Object.keys(errors).length > 0 ? 'Please resolve validation errors' : null}
      type="button"
    >
      {buildLabel(isSubmitting, 'Save')}
    </button>
  );
};

const buildSaveAndNext = (errors, isSubmitting, setFieldValue, submitForm) => {
  return (
    <button
      className="btn btn-success ml-3"
      data-toggle="tooltip"
      disabled={Object.keys(errors).length > 0 ? true : isSubmitting}
      onClick={() => {
        setFieldValue('redirect', true, false);
        submitForm();
      }}
      title={Object.keys(errors).length > 0 ? 'Please resolve validation errors' : null}
      type="button"
    >
      {buildLabel(isSubmitting, 'Save and Next')}
    </button>
  );
};

const buildSubmit = (isSubmitting) => {
  return (
    <button className="btn btn-block btn-primary" disabled={isSubmitting} type="submit">
      {buildLabel(isSubmitting, 'Submit')}
    </button>
  );
};

const buildTimestamp = (timestamp) => {
  if (timestamp === null) {
    return 'N/A';
  }
  return format(parseISO(timestamp), 'yyyy-LL-dd hh:mm:ss aaa');
};

const buildTitle = (classification, strings) => {
  const title = _.join(strings.length ? _.concat([], [''], strings) : [], ' - ');
  if (classification === 'Cardinal Community') {
    return (
      <React.Fragment>
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faBuilding} />
        Cardinal Communities {title}
      </React.Fragment>
    );
  }
  if (classification === 'Non-Cardinal Community') {
    return (
      <React.Fragment>
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faHotel} />
        Non-Cardinal Communities {title}
      </React.Fragment>
    );
  }
  return null;
};

export {buildCancel, buildClassName, buildNext, buildSave, buildSaveAndNext, buildSubmit, buildTimestamp, buildTitle};
