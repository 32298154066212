import {connect, ErrorMessage, Field} from 'formik';
import _ from 'lodash';
import React from 'react';
import InputMask from 'react-input-mask';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {reach} from 'yup';

import {buildClassName} from '../builders';

class Slot extends React.Component {
  render = () => {
    return (
      <React.Fragment>
        {this.renderCheckbox()}
        {this.renderInput()}
        {this.renderInputDollar()}
        {this.renderInputManagement()}
        {this.renderInputMask()}
        {this.renderInputURL()}
        {this.renderSelectAll()}
        {this.renderSelectOne()}
        {this.renderTextarea()}
      </React.Fragment>
    );
  };

  renderCheckbox = () => {
    if (this.props.component !== 'checkbox') {
      return null;
    }
    if (this.props.special === true) {
      return (
        <div className="form-group">
          <label htmlFor={this.props.name}>
            {this.props.label} {this.renderRequired()}
          </label>
          <Field
            checked={this.props.checked}
            className="form-control form-control-checkbox"
            component="input"
            disabled={this.props.disabled}
            id={this.props.name}
            name={this.props.name}
            type="checkbox"
          />
        </div>
      );
    }
    return (
      <div className="form-check">
        <Field
          checked={this.props.checked}
          className="form-check-input"
          component="input"
          disabled={this.props.disabled}
          id={this.props.name}
          name={this.props.name}
          type="checkbox"
        />
        <label className="form-check-label" htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
      </div>
    );
  };

  renderInput = () => {
    if (this.props.component !== 'input') {
      return null;
    }
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
        <Field
          className={`form-control ${buildClassName(this.props.errors, this.props.touched)}`}
          component="input"
          disabled={this.props.disabled}
          id={this.props.name}
          max={this.props.max}
          min={this.props.min}
          name={this.props.name}
          step={this.props.step}
          type={this.props.type}
        />
        {this.renderError()}
      </div>
    );
  };

  renderInputDollar = () => {
    if (this.props.component !== 'input-dollar') {
      return null;
    }
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
        <Field
          className={`form-control ${buildClassName(this.props.errors, this.props.touched)}`}
          component="input"
          disabled={this.props.disabled}
          id={this.props.name}
          max={this.props.max}
          min={this.props.min}
          name={this.props.name}
          onChange={this.props.onChange}
          step={this.props.step}
          type={this.props.type}
        />
        {this.renderError()}
      </div>
    );
  };

  renderInputManagement = () => {
    if (this.props.component !== 'input-management') {
      return null;
    }
    const options = _.uniq(_.filter(_.concat(this.props.options, this.props.value), (option) => !!option));
    options.sort();
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
        <CreatableSelect
          defaultValue={{label: this.props.value, value: this.props.value}}
          id={this.props.name}
          isClearable={false}
          isDisabled={this.props.isDisabled}
          name={this.props.name}
          onChange={this.props.onChange}
          onInputChange={this.props.onChange}
          options={options.map((option) => {
            return {
              value: option,
              label: option,
            };
          })}
        />
        {this.renderError()}
      </div>
    );
  };

  renderInputMask = () => {
    if (this.props.component !== 'input-mask') {
      return null;
    }
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
        <InputMask
          className={`form-control ${buildClassName(this.props.errors, this.props.touched)}`}
          disabled={this.props.disabled}
          id={this.props.name}
          mask={this.props.mask}
          name={this.props.name}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        {this.renderError()}
      </div>
    );
  };

  renderInputURL = () => {
    if (this.props.component !== "url") {
      return null;
    }
    return (
      <Field name={this.props.name}>
        {({ field, form }) => {
          return (
            <div className="form-group">
              <label htmlFor={this.props.name}>{this.props.label}</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">https://</span>
                </div>
                <input
                  className={`form-control`}
                  id={this.props.name}
                  name={this.props.name}
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    let fieldValue = e.target.value.trim();
                    fieldValue = fieldValue.replace(/^http:\/\//i, "https://");
                    if (!/^https?:\/\//i.test(fieldValue)) {
                      fieldValue = `https://${fieldValue}`;
                    }
                    form.setFieldValue(this.props.name, fieldValue);
                  }}
                  placeholder="example.com"
                  type="text"
                  value={field.value ? field.value.replace(/(^\w+:|^)\/\//, "") : ""}
                />
              </div>
            </div>
          );
        }}
      </Field>
    );
  };

  renderSelectAll = () => {
    if (this.props.component !== 'select-all') {
      return null;
    }
    const theme = (theme) => {
      return {
        ...theme,
        borderRadius: 0,
        spacing: {
          ...theme.spacing,
          controlHeight: 30,
        },
      };
    };
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
        <Field
          className={`${this.props.className} ${buildClassName(this.props.errors, this.props.touched)}`}
          component={Select}
          errors={this.props.errors}
          id={this.props.name}
          isDisabled={this.props.disabled}
          isMulti={true}
          name={this.props.name}
          noOptionsMessage={() => this.props.noOptionsMessage}
          onBlur={() => this.props.onBlur(this.props.name, true)}
          onChange={(value) => this.props.onChange(this.props.name, value || [])}
          options={this.props.options}
          theme={theme}
          type={this.props.type}
          value={this.props.value}
        />
        {this.renderError()}
      </div>
    );
  };

  renderSelectOne = () => {
    if (this.props.component !== 'select-one') {
      return null;
    }
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
        <Field
          className={`form-control ${buildClassName(this.props.errors, this.props.touched)}`}
          component="select"
          disabled={this.props.disabled}
          name={this.props.name}
        >
          <option value="">Select...</option>
          {_.map(this.props.options, (o) => {
            return (
              <option key={o} value={o}>
                {o}
              </option>
            );
          })}
        </Field>
        {this.renderError()}
      </div>
    );
  };

  renderTextarea = () => {
    if (this.props.component !== 'textarea') {
      return null;
    }
    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>
          {this.props.label} {this.renderRequired()}
        </label>
        <Field
          className={`form-control ${buildClassName(this.props.errors, this.props.touched)}`}
          component="textarea"
          disabled={this.props.disabled}
          name={this.props.name}
          rows={this.props.rows}
          value={this.props.value}
        />
        {this.renderError()}
      </div>
    );
  };

  renderRequired = () => {
    const {formik, name} = this.props;
    const validationSchema = formik.validationSchema;
    if (!validationSchema.fields[name]) {
      return null;
    }
    const fieldValidationSchema = validationSchema
      ? reach(validationSchema, name, formik.values, formik.values)
      : false;
    const resolvedSchema = fieldValidationSchema ? fieldValidationSchema.resolve({value: formik.values}) : false;
    const tests = resolvedSchema ? resolvedSchema.describe().tests : false;
    const isRequired = tests ? !!tests.find((test) => test.name === 'required') : false;
    if (!isRequired) {
      return null;
    }
    return <span className="required">*</span>;
  };

  renderError = () => {
    return <ErrorMessage className="invalid-feedback" component="div" name={this.props.name} />;
  };
}

Slot = connect(Slot);

export {Slot};
